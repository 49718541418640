<template>
  <v-dialog
    :value="true"
    max-width="500px"
  >
    <v-card>
      <v-card-text>
        <v-select
          :label="$t('item')"
          :items="items"
          item-value="uuid"
          item-text="title"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'LineItemDialog',
    props: {
        value: {
            type: Object, 
            default: () => {}
        }
    },
    computed: {
        items() {
            return this.$store.state.items
        }
    }
}
</script>